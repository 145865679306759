import React from "react";
import AddIcon from "@mui/icons-material/Add";
import { Button, Stack } from "@mui/material";
//Component
export default function AddSubSectionButton({ onClick, name }) {
  return (
    <Stack width="100%" p={1}>
      <Button
        variant="outlined"
        startIcon={<AddIcon />}
        sx={{
          color: "#1B418C",
          borderColor: "#1B418C",
          borderRadius: "16px",
          width: "20%",
          alignSelf: "center",
          alignContent: "center",
        }}
        onClick={onClick}
        data-testid="addExp"
      >
        {name}
      </Button>
    </Stack>
  );
}
