import {
  FormDataSchema,
  ServerFormDataSchema,
  SubmitResumeResponse,
  ValidationError,
} from "../types/formtypes";
import { ensureError } from "../utils/utils";

import {
  POST_FORM_2_DOC,
  SERVER,
  SERVER_API_KEY,
  SERVER_API_SECRET,
} from "./server";

export async function submitFormRequest(
  data: ServerFormDataSchema,
): Promise<SubmitResumeResponse | ValidationError> {
  const link = SERVER + POST_FORM_2_DOC;
  try {
    const response = await fetch(link, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "x-api-key": SERVER_API_KEY,
        "x-api-key-secret": SERVER_API_SECRET,
      },
      body: JSON.stringify(data),
    });
    if (response.status === 422) {
      const errorMessage: ValidationError = await response.json();
      return errorMessage;
    } else if (response.status === 200) {
      const formData: SubmitResumeResponse = await response.json();
      return formData;
    } else {
      throw new Error(`Error status code: ${response.status}`);
    }
  } catch (err) {
    const error = ensureError(err);
    console.error("Error submitting form request:", error);
    throw new Error(`Failed to submit form request: ${error.message}`);
  }
}
