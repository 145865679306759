import React, { useState } from "react";
import {
  Stack,
  Accordion,
  AccordionSummary,
  Typography,
  AccordionDetails,
  FormControlLabel,
  Checkbox,
  FormControl,
  FormHelperText,
} from "@mui/material";
import ConfirmationModal from "../ConfirmationModal";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ListBullets from "./ListBullets";
import { Controller, useFormContext, useFieldArray } from "react-hook-form";
import dayjs from "dayjs";
import AddSubSectionButton from "../UI/AddSubSectionButton";
import DeleteSectionButton from "../UI/DeleteSectionButton";
import { BULLET_DEFAULT_OBJECT } from "../../utils/Constants";
import FormTextField from "../UI/FormTextField";
import DateField from "../UI/DateField";

export default function ItemExperience(props) {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const deleteExp = () => {
    handleClose();
    removeExperience(props.index);
  };

  const duplicateExp = () => {
    handleClose();
    // TODO: DUPLICATE FUNCTIONALITY WITH REACTHOOKFORMS
  };

  const [isPresent, setIsPresent] = useState(false);

  const {
    control,
    formState: { errors },
    setValue,
  } = useFormContext();
  const { removeExperience } = props;
  const {
    fields: bullets,
    append: appendBullet,
    remove: removeBullet,
  } = useFieldArray({
    control,
    name: `experience[${props.index}].bullets`,
  });

  const appendBulletClick = () => {
    appendBullet(BULLET_DEFAULT_OBJECT);
  };

  const handleIsPresent = () => {
    setIsPresent(!isPresent);
    const today = dayjs();

    if (!isPresent) {
      setValue(
        `experience.${props.index}.end_date`,
        today.format("YYYY-MM-DD"),
      );
    } else {
      setValue(`experience.${props.index}.end_date`, "");
    }
  };

  return (
    <Stack
      id={`experience_${props.index}_id`}
      direction="column"
      gap={2}
      padding={2}
    >
      <Stack direction={"row"} justifyContent={"space-between"}>
        <Controller
          name={`experience.${props.index}.company`}
          control={control}
          render={({ field, fieldState: { error } }) => (
            <FormTextField
              field={field}
              error={error}
              label="Company"
              name="company"
              placeholder="Company"
              sx={{ width: "25%" }}
            />
          )}
        />
        <Controller
          name={`experience.${props.index}.title`}
          control={control}
          render={({ field, fieldState: { error } }) => (
            <FormTextField
              field={field}
              error={error}
              label="Title"
              name="title"
              placeholder="Title"
              sx={{ width: "25%" }}
            />
          )}
        />
        <FormControl>
          <Controller
            name={`experience.${props.index}.start_date`}
            control={control}
            render={({ field }) => <DateField label={"Start"} field={field} />}
          />
          <FormHelperText error={true}>
            {!!errors.experience?.[props.index]?.start_date
              ? errors.experience?.[props.index]?.start_date?.message
              : ""}
          </FormHelperText>
        </FormControl>
        <Stack direction={"column"}>
          <FormControl>
            <Controller
              name={`experience.${props.index}.end_date`}
              control={control}
              render={({ field }) => (
                <DateField label="End" field={field} isPresent={isPresent} />
              )}
            />
            <FormHelperText error={true}>
              {!!errors.experience?.[props.index]?.end_date
                ? errors.experience?.[props.index]?.end_date?.message
                : ""}
            </FormHelperText>
          </FormControl>
          <FormControlLabel
            control={
              <Checkbox
                checked={isPresent}
                onChange={() => handleIsPresent()}
              />
            }
            label="Is Present?"
          />
        </Stack>
      </Stack>
      <Accordion
        defaultExpanded
        sx={{
          borderRadius: "5px",
          boxShadow: "none",
          border: "1px solid #cbcbcb",
          bgcolor: "#f5f5f5",
        }}
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>Bullets</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <ListBullets
            experienceIndex={props.index}
            bullets={bullets}
            remove={removeBullet}
          />
        </AccordionDetails>
        <AddSubSectionButton name={"Add Bullet"} onClick={appendBulletClick} />
      </Accordion>

      <Stack direction={"row"} justifyContent={"center"} spacing={1}>
        <DeleteSectionButton
          deleteName={"DELETE EXPERIENCE"}
          handleShow={handleShow}
        />
        <ConfirmationModal
          show={show}
          handleClose={handleClose}
          handleConfirmation={deleteExp}
          actionName={"Delete Experience?"}
        />
      </Stack>
      <hr />
    </Stack>
  );
}
