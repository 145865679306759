import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogActions,
  Button,
  DialogContent,
  DialogContentText,
} from "@mui/material";

export const ErrorDialog = ({
  handleCloseDialog,
  open,
  message,
}): JSX.Element => {
  return (
    <Dialog
      open={open}
      onClose={handleCloseDialog}
      aria-labelledby="responsive-dialog-title"
    >
      <DialogTitle id="responsive-dialog-title">{"Server Error"}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Make sure to ping Factored Resume Form Team{" "}
          <a
            href={"https://factoredai.slack.com/archives/C03NG7V8R8C"}
            target="_blank"
          >
            here.
          </a>
        </DialogContentText>
        <DialogContentText>{"Error Message: " + message}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          variant="extended"
          aria-label="Submit Button"
          sx={{
            bgcolor: "#1B418C",
            color: "white",
            "&:hover": {
              backgroundColor: "#0071B2",
            },
          }}
          autoFocus
          onClick={handleCloseDialog}
        >
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};
