import React from "react";
import "../../styles/Education.css";
import ItemEducation from "./ItemEducation";
import { Stack, Typography } from "@mui/material";
import InfoBtn from "../UI/InfoBtn";
import {
  EDUCATION_COMPLETE_INFO,
  EDUCATION_LIGHT_INFO,
} from "../../utils/Constants";
//Component
import { useFormContext, useFieldArray } from "react-hook-form";
import AddSectionButton from "../UI/AddSectionButton";

import { EDUCATION_DEFAULT_OBJECT } from "../../utils/Constants";

export default function Education(): JSX.Element {
  const { control } = useFormContext();

  const {
    fields: educations,
    append: appendEducation,
    remove: removeEducation,
  } = useFieldArray({
    control,
    name: `education`,
  });

  const addEducationSection = () => {
    console.log("Running");
    appendEducation(EDUCATION_DEFAULT_OBJECT);
  };

  return (
    <Stack>
      <Stack direction="row" gap={1} pl={2}>
        <Typography id="education_id" variant="h5" style={{ marginTop: "3px" }}>
          Education
        </Typography>
        <InfoBtn
          titleInfo="Experience"
          bodyInfo={
            <div
              dangerouslySetInnerHTML={{
                __html: EDUCATION_LIGHT_INFO,
              }}
            />
          }
          completeInfo={
            <div
              dangerouslySetInnerHTML={{
                __html: EDUCATION_COMPLETE_INFO,
              }}
            />
          }
        />
      </Stack>
      {educations
        ? educations.map((education, index) => (
            <ItemEducation
              key={education.id}
              index={index}
              removeEducation={removeEducation}
            />
          ))
        : null}
      <AddSectionButton name={"ADD EDUCATION"} onClick={addEducationSection} />
      <hr />
    </Stack>
  );
}
