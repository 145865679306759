import React from "react";
import { Button, Stack } from "@mui/material";
import { Controller, useFormContext } from "react-hook-form";
import FormTextField from "../UI/FormTextField";

export default function ItemPublication(props) {
  const { removePublication } = props;
  const { control } = useFormContext();

  return (
    <Stack id={`publications_${props.index}_id`} direction={"row"}>
      <Controller
        name={`publications.[${props.index}].text`}
        control={control}
        render={({ field, fieldState: { error } }) => (
          <FormTextField
            field={field}
            error={error}
            placeholder="Description"
            multiline
            rows={3}
            sx={{ width: "95%" }}
          />
        )}
      />
      <Button
        variant="contained"
        onClick={() => removePublication(props.index)}
        disableElevation={true}
        sx={{
          color: "red",
          backgroundColor: "#F2F2F2",
          "&:hover": {
            backgroundColor: "lightgray",
          },
        }}
      >
        x
      </Button>
    </Stack>
  );
}
