import React from "react";
import ItemCourse from "./ItemCourse";

export default function ListCourses({ removeCourse, fields }) {
  return (
    <>
      {fields
        ? fields.map((course, index) => (
            <ItemCourse
              key={course.id}
              index={index}
              removeCourse={removeCourse}
            />
          ))
        : null}
    </>
  );
}
