import React, { useEffect, useState } from "react";
import "../../styles/TableOfContents.css";
import {
  Button,
  Stack,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { useFormContext } from "react-hook-form";
import type { FormDataSchema, TableType } from "../../types/formtypes";
import { TABLE_OF_CONTENTS_DEFAULT_OBJECT } from "../../utils/Constants";
import { convertFormDataToTableOfContents } from "../../utils/utils";
import InfoBtn from "../UI/InfoBtn";

function TableOfContents(): JSX.Element {
  const { watch } = useFormContext<FormDataSchema>();
  const [sections, setSections] = useState<TableType>(
    TABLE_OF_CONTENTS_DEFAULT_OBJECT.sections,
  );

  const [expanded, setExpanded] = useState<string | false>(false);
  const [hoveredPanel, setHoveredPanel] = useState<string | false>(false);

  const handleScroll = (id: string) => {
    const element = document.querySelector(`#${id}`);
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
      if (element instanceof HTMLElement) {
        element.focus({ preventScroll: true });
      }
    }
  };

  useEffect(() => {
    const subscription = watch((data: unknown) => {
      setSections(convertFormDataToTableOfContents(data).sections);
    });
    return () => {
      subscription.unsubscribe();
    };
  }, [watch]);

  const handleMouseEnter = (panel: string) => {
    setHoveredPanel(panel);
  };

  return (
    <nav>
      <Stack spacing={5} marginTop={10}>
        <Stack direction={"row"}>
          <Typography variant="h4" fontWeight={"bold"}>
            Quick Access Table
          </Typography>
          <InfoBtn
            titleInfo="Summary"
            bodyInfo={
              <div
                dangerouslySetInnerHTML={{
                  __html: "Tap a Section to Scroll into View",
                }}
              />
            }
            completeInfo={
              <div
                dangerouslySetInnerHTML={{
                  __html:
                    "Hover over a section to expand it, and click on a sub-section to scroll the window to that sub-section.",
                }}
              />
            }
          />
        </Stack>
        <div>
          {Object.entries(sections).map(([key, sectionList]) => (
            <Accordion
              defaultExpanded={true}
              expanded={expanded === key || hoveredPanel === key}
              key={key}
              sx={{ bgcolor: "#F6F6F6", textAlign: "left" }}
              onMouseEnter={() => handleMouseEnter(key)}
            >
              <AccordionSummary expandIcon={<ArrowDropDownIcon />}>
                <Typography variant="h6" fontWeight={"570"} textAlign={"left"}>
                  {sectionList.text}
                </Typography>
              </AccordionSummary>
              <AccordionDetails sx={{ padding: 0 }}>
                <ul>
                  {sectionList.sub_sections.map((sub_section) => (
                    <li key={sub_section.id}>
                      <Button
                        onClick={() => handleScroll(sub_section.id)}
                        fullWidth
                        style={{
                          textAlign: "left",
                          justifyContent: "flex-start",
                          display: "flex",
                        }}
                      >
                        <Typography
                          fontSize={18}
                          color={"black"}
                          style={{ textTransform: "none", textAlign: "left" }}
                          fontWeight={"400"}
                        >
                          {sub_section.text}
                        </Typography>
                      </Button>
                    </li>
                  ))}
                </ul>
              </AccordionDetails>
            </Accordion>
          ))}
        </div>
      </Stack>
    </nav>
  );
}

export default TableOfContents;
