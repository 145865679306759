import React from "react";
import { Stack, Typography, Box } from "@mui/material";
import ItemThesisProject from "./ItemThesisProject";
import { useFormContext, useFieldArray } from "react-hook-form";
import AddSubSectionButton from "../UI/AddSubSectionButton";
import { EDUCATION_PROJECT_DEFAULT } from "../../utils/Constants";
export default function ListThesisProjects({ educationIndex }) {
  const { control } = useFormContext();

  const {
    fields: educationProjects,
    append: appendEducationProject,
    remove: removeEducationProject,
  } = useFieldArray({
    control,
    name: `education[${educationIndex}].education_projects`,
  });

  const appendEducationProjectClick = () => {
    appendEducationProject(EDUCATION_PROJECT_DEFAULT);
  };
  return (
    <>
      <Stack
        direction="column"
        alignItems="center"
        spacing={2}
        width={"100%"}
        p={1}
      >
        <Typography>Thesis/Projects</Typography>
        <Stack direction="column" spacing={2} width={"100%"}>
          {educationProjects
            ? educationProjects.map((thesisProject, index) => (
                <ItemThesisProject
                  key={thesisProject.id}
                  index={index}
                  educationIndex={educationIndex}
                  remove={removeEducationProject}
                />
              ))
            : null}
        </Stack>
      </Stack>
      <Box mt={2}>
        <AddSubSectionButton
          onClick={appendEducationProjectClick}
          name={"Add Thesis/Project"}
        />
      </Box>
      <hr />
    </>
  );
}
