import React from "react";
import ItemBullet from "./ItemBullet";

export default function ListBullets({ bullets, experienceIndex, remove }) {
  return (
    <>
      {bullets
        ? bullets.map((bullet, index) => (
            <ItemBullet
              experienceIndex={experienceIndex}
              key={bullet.id}
              index={index}
              remove={remove}
            />
          ))
        : null}
    </>
  );
}
