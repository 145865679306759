import React, { useState, useEffect } from "react";
import { Stack } from "@mui/material";
import logo from "../assets/Factored logo.png";
import SaveBtn from "./UI/SaveBtn";
import OpenRecentBtn from "./UI/OpenRecentBtn";
import { ModalProvider } from "../features/ModalContext";
import OpenFile from "./UI/Openfile";
import SubmitBtn from "./UI/SubmitBtn";
import { useFormContext } from "react-hook-form";
import LinearProgressWithLabel from "./UI/LinearProgressWithLabel";
import { FormDataSchema } from "../types/formtypes";
import { FORM_FIELDS } from "../utils/Constants";
import Spinner from "../components/UI/Spinner";
import ScrollUpButton from "./UI/ScrollUpButton";

export default function MainLayout({ handleSubmitTrigger, loading }) {
  const [filledPercentage, setFilledPercentage] = useState(0);

  const {
    watch,
    formState: { errors },
  } = useFormContext<FormDataSchema>();

  const countFields = (obj: FormDataSchema) => {
    let total = 0;
    let filled = 0;

    for (const key in obj) {
      if (typeof obj[key] === "object" && obj[key] !== null) {
        const nestedCount = countFields(obj[key]);
        total += nestedCount.total;
        filled += nestedCount.filled;
      } else {
        if (!FORM_FIELDS.has(key)) {
          continue;
        }

        total += 1;
        filled += 1;
        if (
          obj[key] === undefined ||
          obj[key] === null ||
          obj[key] === "" ||
          obj[key] === 0
        ) {
          filled -= 1;
        }
      }
    }
    return { total, filled };
  };
  useEffect(() => {
    const subscription = watch((data: unknown) => {
      const { total, filled } = countFields(data);
      const percentage = (filled / total) * 100;
      setFilledPercentage(percentage);
    });
    return () => {
      subscription.unsubscribe();
    };
  }, [watch]);

  return (
    <ModalProvider>
      <>
        <Stack
          direction="row"
          spacing={2}
          pl={4}
          bgcolor={"#1B418C"}
          sx={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            zIndex: 9999,
          }}
        >
          <img src={logo} alt="Factored logo" style={{ width: 200 }} />
          <Stack width={"100%"} justifyContent={"center"}>
            <LinearProgressWithLabel value={filledPercentage} />
          </Stack>
        </Stack>

        <Stack
          direction="row"
          sx={{
            position: "fixed",
            bottom: 16,
            right: 16,
            zIndex: 1000,
            alignItems: "flex-end",
          }}
        >
          <Stack
            direction="row"
            spacing={2}
            sx={{
              display: "flex",
              alignItems: "flex-end",
            }}
          >
            <ScrollUpButton />
            <Stack direction="column" spacing={2}>
              <SaveBtn />
              <OpenRecentBtn />
              <OpenFile />
              {loading ? (
                <Spinner />
              ) : (
                <SubmitBtn handleSubmitTrigger={handleSubmitTrigger} />
              )}
            </Stack>
          </Stack>
        </Stack>
      </>
    </ModalProvider>
  );
}
