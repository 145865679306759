import { z } from "zod";

export const certificationSchema = z.object({
  certification_title: z
    .string()
    .min(1, { message: "Certification title is required" }),
  certification_institution: z
    .string()
    .min(1, { message: "Institution is required" }),
});
