import { ServerFormDataSchema } from "../types/formtypes";
import {
  SERVER,
  SERVER_API_KEY,
  SERVER_API_SECRET,
  GET_DOC,
  GET_LIST_DOCS,
} from "./server";

import { ensureError } from "../utils/utils";
export async function getRecentForm(
  selected: string,
): Promise<ServerFormDataSchema> {
  const link = SERVER + GET_DOC + "?resume_key=" + selected;
  try {
    const response = await fetch(link, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "x-api-key": SERVER_API_KEY,
        "x-api-key-secret": SERVER_API_SECRET,
      },
    });
    const serverFormData = await response.json();
    return serverFormData.resume;
  } catch (err) {
    const error = ensureError(err);
    throw new Error(`Error loading Recent Data: ${error.message}`);
  }
}

export async function getFormNames() {
  const link = SERVER + GET_LIST_DOCS;
  try {
    const response = await fetch(link, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "x-api-key": SERVER_API_KEY,
        "x-api-key-secret": SERVER_API_SECRET,
      },
    });
    const formList = await response.json();
    return formList.resume_keys;
  } catch (err) {
    const error = ensureError(err);
    throw new Error(
      `| getFormNames() | Error loading Form Names: ${error.message}`,
    );
  }
}
