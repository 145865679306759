import React from "react";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
//Component
export default function DateField({ label, field, isPresent = false }) {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DatePicker
        label={label}
        inputRef={field.ref}
        onBlur={field.onBlur}
        views={["month", "year"]}
        value={isPresent ? dayjs() : field.value ? dayjs(field.value) : null} // Ensure value is Dayjs or null
        onChange={(newValue) => {
          field.onChange(newValue ? newValue.format("YYYY-MM-DD") : "");
        }}
        disableFuture
      />
    </LocalizationProvider>
  );
}
