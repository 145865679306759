import React from "react";
import "../../styles/Experience.css";
import ItemExperience from "./ItemExperience";
import { Stack, Typography } from "@mui/material";
import InfoBtn from "../UI/InfoBtn";

import {
  EXPERIENCE_COMPLETE_INFO,
  EXPERIENCE_LIGHT_INFO,
  EXPERIENCE_DEFAULT_OBJECT,
} from "../../utils/Constants";

import { useFormContext, useFieldArray } from "react-hook-form";
import AddSectionButton from "../UI/AddSectionButton";
import {
  FormDataSchema,
  TableOfContentsContextType,
} from "../../types/formtypes";
export default function Experience(): JSX.Element {
  const { control } = useFormContext<FormDataSchema>();

  const {
    fields: experiences,
    append: appendExperience,
    remove: removeExperience,
  } = useFieldArray({
    control,
    name: `experience`,
  });

  const appendButtonClick = () => {
    appendExperience(EXPERIENCE_DEFAULT_OBJECT);
  };

  return (
    <>
      <Stack>
        <Stack direction="row" gap={1} pl={2}>
          <Typography
            id="experience_id"
            variant="h5"
            style={{ marginTop: "3px" }}
          >
            Experience
          </Typography>
          <InfoBtn
            titleInfo="Experience"
            bodyInfo={
              <div
                dangerouslySetInnerHTML={{
                  __html: EXPERIENCE_LIGHT_INFO,
                }}
              />
            }
            completeInfo={
              <div
                dangerouslySetInnerHTML={{
                  __html: EXPERIENCE_COMPLETE_INFO,
                }}
              />
            }
          />
        </Stack>
        <Stack direction="column" spacing={2} pt={1}>
          {experiences.map((experience, index) => (
            <ItemExperience
              key={experience.id}
              index={index}
              experience={experience}
              removeExperience={removeExperience}
            />
          ))}
        </Stack>
        <AddSectionButton name={"ADD EXPERIENCE"} onClick={appendButtonClick} />
        <hr />
      </Stack>
    </>
  );
}
