import React from "react";
import "../../styles/Certifications.css";
import ListCertifications from "./ListCertifications";
import ListCourses from "./ListCourses";

import { Stack, Typography } from "@mui/material";
import InfoBtn from "../UI/InfoBtn";
import {
  CERT_LIGHT_INFO,
  CERTIFICATION_DEFAULT_OBJECT,
  COURSE_DEFAULT_OBJECT,
} from "../../utils/Constants";
import { useFormContext, useFieldArray } from "react-hook-form";
import AddSubSectionButton from "../UI/AddSubSectionButton";
import { FormDataSchema } from "../../types/formtypes";

//Component
export default function Certifications(): JSX.Element {
  const methods = useFormContext<FormDataSchema>();
  const {
    fields: certifications,
    append: appendCertification,
    remove: removeCertification,
  } = useFieldArray({
    control: methods.control,
    name: "certifications",
  });

  const {
    fields: courses,
    append: appendCourse,
    remove: removeCourse,
  } = useFieldArray({
    control: methods.control,
    name: "courses",
  });

  const addCourse = () => {
    appendCourse(COURSE_DEFAULT_OBJECT);
  };

  const addCertification = () => {
    appendCertification(CERTIFICATION_DEFAULT_OBJECT);
  };
  return (
    <Stack direction={"column"}>
      <Stack direction="row" gap={1} p={2}>
        <Typography
          id="certifications-courses-id"
          variant="h5"
          style={{ marginTop: "3px" }}
        >
          Certifications and Courses
        </Typography>
        <InfoBtn
          titleInfo="Certifications and Courses"
          bodyInfo={
            <div
              dangerouslySetInnerHTML={{
                __html: CERT_LIGHT_INFO,
              }}
            />
          }
          completeInfo={
            <div
              dangerouslySetInnerHTML={{
                __html: CERT_LIGHT_INFO,
              }}
            />
          }
        />
      </Stack>
      <Stack direction={"row"} pl={2} gap={4}>
        <Typography variant="h6">Certifications</Typography>
        <Stack direction={"column"} width={"100%"}>
          <ListCertifications
            removeCertification={removeCertification}
            fields={certifications}
          />
        </Stack>
      </Stack>
      <AddSubSectionButton
        onClick={addCertification}
        name={"Add Certification"}
      />

      <Stack direction={"row"} pl={2} gap={4}>
        <Typography variant="h6">Courses</Typography>
        <Stack direction={"column"} width={"100%"}>
          <ListCourses removeCourse={removeCourse} fields={courses} />
        </Stack>
      </Stack>
      <AddSubSectionButton onClick={addCourse} name={"Add Course"} />
      <hr />
    </Stack>
  );
}
