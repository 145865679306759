import React from "react";
import {
  PROJECT_COMPLETE_INFO,
  PROJECT_LIGHT_INFO,
} from "../../utils/Constants";
import InfoBtn from "../UI/InfoBtn";
import { Stack, Typography } from "@mui/material";
import ItemProject from "./ItemProject";

//Component
export default function ListProjects({ projects, removeProject }) {
  return (
    <Stack direction={"row"} pl={2} gap={4} alignItems={"flex-start"}>
      <Typography variant="h6">Projects</Typography>
      <InfoBtn
        titleInfo="Projects"
        bodyInfo={
          <div
            dangerouslySetInnerHTML={{
              __html: PROJECT_LIGHT_INFO,
            }}
          />
        }
        completeInfo={
          <div
            dangerouslySetInnerHTML={{
              __html: PROJECT_COMPLETE_INFO,
            }}
          />
        }
      />
      <Stack direction={"column"} gap={1} width={"100%"}>
        {projects
          ? projects.map((proj, index) => (
              <ItemProject
                key={proj.id}
                index={index}
                removeProject={removeProject}
              />
            ))
          : null}
      </Stack>
    </Stack>
  );
}
