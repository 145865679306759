import React from "react";

export const ModalOpenContext = React.createContext();
export const ModalUploadContext = React.createContext();

export function useModalContextOpen() {
  const context = React.useContext(ModalOpenContext);
  if (!context) {
    throw new Error("useModalContext must be used within a ModalProvider");
  }
  return context;
}

export function useModalContextUpload() {
  const context = React.useContext(ModalUploadContext);
  if (!context) {
    throw new Error("useModalContext must be used within a ModalProvider");
  }
  return context;
}

export const ModalProvider = ({ children }) => {
  const [show, setShow] = React.useState(null);
  const [showUpload, setShowUpload] = React.useState(null);

  const modalOpenValue = React.useMemo(
    () => ({ show, setShow }),
    [show, setShow],
  );
  const modalUploadValue = React.useMemo(
    () => ({ showUpload, setShowUpload }),
    [showUpload, setShowUpload],
  );

  return (
    <ModalOpenContext.Provider value={modalOpenValue}>
      <ModalUploadContext.Provider value={modalUploadValue}>
        {children}
      </ModalUploadContext.Provider>
    </ModalOpenContext.Provider>
  );
};
