import React from "react";
import { Button } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";

//Component
export default function AddSectionButton({ onClick, name }) {
  return (
    <Button
      variant="contained"
      startIcon={<AddIcon />}
      sx={{
        backgroundColor: "#1B418C",
        color: "white",
        borderRadius: "16px",
        width: "20%",
        alignSelf: "center",
      }}
      onClick={onClick}
      data-testid="addExp"
    >
      {name}
    </Button>
  );
}
