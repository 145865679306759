import React, { useState, useImperativeHandle, forwardRef } from "react";
import { useForm, FormProvider, FieldErrors } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { formSchema } from "./schemas/formschema";
import { NotificationContainer } from "react-notifications";
import "react-notifications/lib/notifications.css";
import "./styles/App.css";
import BasicInfo from "./components/BasicInfo/BasicInfo";
import Certifications from "./components/Certifications/Certifications";
import Education from "./components/Education/Education";
import Experience from "./components/Experience/Experience";
import Personal from "./components/PersonalProjectsAndPublications/Personal";
import { Stack } from "@mui/material";
import { SuccessDialog } from "./components/UI/SuccessDialog";
import { DEFAULT_VALUES } from "./utils/Constants";
import MainLayout from "./components/MainLayout";

import TableOfContents from "./components/UI/TableOfContents";
import { FormDataSchema, MyFormHandle, MyFormProps } from "./types/formtypes";
import { ErrorDialog } from "./components/UI/ErrorDialog";

const MyForm = forwardRef<MyFormHandle, MyFormProps>(
  ({ onSubmit, handleSubmitTrigger }, ref) => {
    const [successOpen, setSuccessOpen] = useState(false);
    const [errorOpen, setErrorOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [serverError, setServerError] = useState(new Error());
    const [linkFormSubmitted, setLinkFormSubmitted] = useState("");
    const methods = useForm<FormDataSchema>({
      resolver: zodResolver(formSchema),
      defaultValues: DEFAULT_VALUES,
      shouldFocusError: true,
      mode: "onBlur",
    });
    const { handleSubmit, setError } = methods;

    const handleErrors = (errors: FieldErrors<FormDataSchema>) => {
      console.error("HANDLING CLIENT ERRORS | MyForm.js");
      console.error(errors);
    };

    useImperativeHandle(ref, () => ({
      submitForm: () => {
        handleSubmit(onSubmit, handleErrors)();
      },
      setErrors: (name: any, message: string) => {
        console.error("Error del Back", message);
        setError(name, { type: "custom", message });
      },
      openSuccessDialog: (document_url: string) => {
        setSuccessOpen(true);
        setLinkFormSubmitted(document_url);
      },
      setLoadingSubmitButton: (state) => {
        setLoading(state);
      },

      openErrorDialog: (error: Error) => {
        setErrorOpen(true);
        setServerError(error);
      },
    }));

    const handleCloseSuccessDialog = () => {
      setSuccessOpen(false);
    };

    const handleCloseErrorDialog = () => {
      setErrorOpen(false);
    };
    return (
      <>
        <Stack
          marginRight={20}
          direction={"row"}
          width={"100%"}
          justifyContent={"space-evenly"}
          alignItems={"center"}
        >
          <FormProvider {...methods}>
            <MainLayout
              handleSubmitTrigger={handleSubmitTrigger}
              loading={loading}
            />
            <TableOfContents />
            <Stack
              direction="row"
              pt={10}
              justifyContent="center"
              marginRight={"10%"}
            >
              <form>
                <h3 className="titleText">Factored Resume Form</h3>
                <BasicInfo />
                <Experience />
                <Certifications />
                <Education />
                <Personal />
                {successOpen && (
                  <SuccessDialog
                    handleCloseSuccessDialog={handleCloseSuccessDialog}
                    open={successOpen}
                    linkFormSubmitted={linkFormSubmitted}
                  />
                )}
                {errorOpen && (
                  <ErrorDialog
                    handleCloseDialog={handleCloseErrorDialog}
                    message={serverError.message}
                    open={errorOpen}
                  />
                )}
                <NotificationContainer />
              </form>
            </Stack>
          </FormProvider>
        </Stack>
      </>
    );
  },
);

export default MyForm;
