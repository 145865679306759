import React from "react";
import { Fab } from "@mui/material";
import SubmitIcon from "@mui/icons-material/Send";
import { NotificationContainer } from "react-notifications";

export default function SubmitBtn({ handleSubmitTrigger }) {
  return (
    <>
      <Fab
        variant="extended"
        aria-label="Submit Button"
        sx={{
          bgcolor: "#1B418C",
          color: "white",
          "&:hover": {
            backgroundColor: "#0071B2",
          },
        }}
        id="submit-btn"
        data-testid="test-submit-btn"
        className="btnApp"
        onClick={handleSubmitTrigger}
      >
        <SubmitIcon />
        Submit
      </Fab>
      <NotificationContainer />
    </>
  );
}
