import React from "react";
import { Stack } from "@mui/material";

import { Controller, useFormContext } from "react-hook-form";
import DeleteSubSectionButton from "../UI/DeleteSubSection";
import FormTextField from "../UI/FormTextField";

export default function ItemCourse(props) {
  const { control } = useFormContext();
  const { removeCourse } = props;
  const removeCurrentCourse = () => {
    removeCourse(props.index);
  };

  return (
    <Stack
      id={`courses_${props.index}_id`}
      direction={"row"}
      width={"100%"}
      gap={1}
      justifyContent={"space-between"}
      padding={1}
    >
      <Controller
        name={`courses.[${props.index}].course_title`}
        control={control}
        render={({ field, fieldState: { error } }) => (
          <FormTextField
            field={field}
            error={error}
            required
            label="Title"
            sx={{ width: "45%" }}
          />
        )}
      />
      <Controller
        name={`courses.[${props.index}].course_institution`}
        control={control}
        render={({ field, fieldState: { error } }) => (
          <FormTextField
            field={field}
            error={error}
            required
            label="Institution"
            sx={{ width: "45%" }}
          />
        )}
      />
      <DeleteSubSectionButton onClick={removeCurrentCourse} />
    </Stack>
  );
}
