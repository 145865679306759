import { z } from "zod";

const educationProjectsSchema = z.object({
  project_type: z.string().min(1, "Project type is required"),
  project_title: z.string().min(1, "Project title is required"),
  project_description: z.string().min(1, "Project description is required"),
});

export const educationSchema = z.object({
  institution: z.string().min(1, "Institution is required"),
  degree_type: z
    .string()
    .min(
      1,
      "Degree type is required (Make sure you enter a valid degree type)",
    ),
  degree_name: z.string().min(1, "Degree name is required"),
  education_status: z.string().min(1, "Education status is required"),
  degree_year: z
    .number()
    .min(1000, "Invalid year (Try using 4 digit numbers)")
    .max(9999, "Invalid year (Try using 4 digit numbers)"),
  education_projects: z.array(educationProjectsSchema),
});
