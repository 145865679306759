import { z } from "zod";
import dayjs from "dayjs";
import { textSchema } from "./textSchema";

export const experienceSchema = z
  .object({
    company: z.string().min(1, { message: "Company name is required" }),
    title: z.string().min(1, { message: "Title is required" }),
    start_date: z.string(), // In Date format('YYYY-MM-DD')
    end_date: z.string(),
    bullets: z.array(textSchema),
  })
  .refine(
    (data) => {
      const startDate = dayjs(data.start_date);
      const endDate = dayjs(data.end_date);
      return startDate.isBefore(endDate) || startDate.isSame(endDate);
    },
    {
      message: "Required Dates (End date must be after or equal to start date)",
      path: ["end_date"], // Highlight the end_date field when there's an error
    },
  );
