// ScrollUpButton.tsx
import React from "react";
import { IconButton } from "@mui/material";
import { ArrowUpward } from "@mui/icons-material";

const ScrollUpButton: React.FC = () => {
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <IconButton
      onClick={scrollToTop}
      sx={{
        "&:hover": {
          backgroundColor: "#145A8C",
          color: "white",
        },
      }}
      color="primary"
    >
      <ArrowUpward
        sx={{
          fontSize: "40px",
        }}
      />
    </IconButton>
  );
};

export default ScrollUpButton;
