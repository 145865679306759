import { SubmitHandler } from "react-hook-form";

export type MyFormProps = {
  onSubmit: SubmitHandler<FormDataSchema>;
  handleSubmitTrigger: () => void;
};

export type MyFormHandle = {
  submitForm: () => void;
  setErrors: (name: string, message: string) => void;
  openSuccessDialog: (document_url: string) => void;
  setLoadingSubmitButton: (state: boolean) => void;
  openErrorDialog: (error: Error) => void;
};

export type ProjectSchema = {
  project_type: string;
  project_title: string;
  project_description: string;
};

export type EducationSchema = {
  institution: string;
  degree_type: string;
  degree_name: string;
  education_status: string;
  degree_year: number;
  education_projects: ProjectSchema[];
};

export type ExperienceBulletSchema = {
  text: string;
};

export type ExperienceSchema = {
  company: string;
  title: string;
  start_date: string;
  end_date: string;
  bullets: ExperienceBulletSchema[];
};

export type CertificationSchema = {
  certification_title: string;
  certification_institution: string;
};

export type CourseSchema = {
  course_title: string;
  course_institution: string;
};

export type PersonalProjectSchema = {
  text: string;
};

export type PublicationSchema = {
  text: string;
};

export type FormDataSchema = {
  name: string;
  role: string;
  email: string;
  summary: string;
  skills: string;
  education: EducationSchema[];
  experience: ExperienceSchema[];
  certifications: CertificationSchema[];
  courses: CourseSchema[];
  personal_projects: PersonalProjectSchema[];
  publications: PublicationSchema[];
};

export type ServerExperienceSchema = {
  company: string;
  title: string;
  start_year: number;
  start_month: string;
  end_year: number;
  end_month: string;
  bullets: string[];
};

export type ServerFormDataSchema = {
  name: string;
  role: string;
  email: string;
  summary: string;
  skills: string;
  education: EducationSchema[];
  experience: ServerExperienceSchema[];
  certifications: CertificationSchema[];
  courses: CourseSchema[];
  personal_projects: string[];
  publications: string[];
};

export type ValidationError = {
  detail: Array<{
    loc: Array<string | number>;
    msg: string;
    type: string;
  }>;
};

export type SubmitResumeResponse = {
  factored_resume_name: string;
  document_url: string;
};

export const isValidationError = (
  response: ValidationError | SubmitResumeResponse,
): response is ValidationError => {
  return (response as ValidationError).detail !== undefined;
};

export type SectionType = {
  text: string;
  id: string;
};

export type TableSection = {
  text: string;
  id: string;
  sub_sections: SectionType[];
};
export type TableType = {
  [key: string]: TableSection;
};
// Changed to use a Map where keys are section IDs and values are TableSection
export type TableOfContentsType = {
  sections: TableType;
};
export type TableOfContentsContextType = {
  sections: TableType;
  addField: (key: string, name: string) => void;
  removeField: (key: string, index: number) => void;
};
