import React from "react";
import { Stack, Typography } from "@mui/material";
import RoleAutocomplete from "../RoleAutocomplete";
import InfoBtn from "../UI/InfoBtn";
import {
  ROLES,
  SKILLS_COMPLETE_INFO,
  SKILLS_LIGHT_INFO,
  SUMMARY_COMPLETE_INFO,
  SUMMAY_LIGHT_INFO,
} from "../../utils/Constants";
import { Controller, useFormContext } from "react-hook-form";
import FormTextField from "../UI/FormTextField";

export default function BasicInfo() {
  const methods = useFormContext();
  const {
    control,
    formState: { errors },
  } = methods;

  return (
    <Stack direction="column" gap={2} padding={2}>
      <Typography variant="h5" id="basic-info-id">
        Basic Info
      </Typography>
      <Stack direction="row" justifyContent={"space-between"} width={"96%"}>
        <Controller
          name="name"
          control={control}
          render={({ field, fieldState: { error } }) => (
            <FormTextField
              field={field}
              error={error}
              label="Name"
              placeholder="Enter your full name"
              sx={{ width: "30%" }}
            />
          )}
        />

        <RoleAutocomplete roles={ROLES} error={errors?.role} />
        <Controller
          name="email"
          control={control}
          render={({ field, fieldState: { error } }) => (
            <FormTextField
              field={field}
              error={error}
              label="Email"
              placeholder="Enter your email"
              sx={{ width: "30%" }}
            />
          )}
        />
      </Stack>
      <Stack direction={"row"} justifyContent={"space-between"}>
        <Controller
          name="summary"
          control={control}
          render={({ field, fieldState: { error } }) => (
            <FormTextField
              field={field}
              error={error}
              label="Summary"
              placeholder="Enter your resume summary"
              multiline
              rows={3}
              sx={{ width: "97%" }}
            />
          )}
        />
        <InfoBtn
          titleInfo="Summary"
          bodyInfo={
            <div dangerouslySetInnerHTML={{ __html: SUMMAY_LIGHT_INFO }} />
          }
          completeInfo={
            <div dangerouslySetInnerHTML={{ __html: SUMMARY_COMPLETE_INFO }} />
          }
        />
      </Stack>
      <Stack direction={"row"} justifyContent={"space-between"}>
        <Controller
          name="skills"
          control={control}
          render={({ field, fieldState: { error } }) => (
            <FormTextField
              field={field}
              error={error}
              label="Skills"
              placeholder="Enter your skills"
              multiline
              rows={3}
              sx={{ width: "100%" }}
            />
          )}
        />
        <InfoBtn
          titleInfo="Skills"
          bodyInfo={
            <div dangerouslySetInnerHTML={{ __html: SKILLS_LIGHT_INFO }} />
          }
          completeInfo={
            <div dangerouslySetInnerHTML={{ __html: SKILLS_COMPLETE_INFO }} />
          }
        />
      </Stack>
      <hr />
    </Stack>
  );
}
