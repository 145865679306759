import { TextField } from "@mui/material";
import React from "react";

//Component
export default function FormTextField({
  field,
  placeholder,
  label,
  error,
  sx,
  ...props
}) {
  return (
    <TextField
      inputRef={field?.ref}
      value={field?.value ?? ""}
      onBlur={field?.onBlur}
      onChange={field?.onChange}
      label={label}
      placeholder={placeholder}
      error={!!error}
      helperText={error?.message}
      sx={sx}
      {...props}
    />
  );
}
