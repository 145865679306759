import React, { useState } from "react";
import InfoSharpIcon from "@mui/icons-material/InfoSharp";
import {
  IconButton,
  Tooltip,
  Popover,
  Button,
  Typography,
} from "@mui/material";

export default function InfoBtn(props) {
  const { bodyInfo, completeInfo } = props;
  const [anchorEl, setAnchorEl] = useState(null);
  const [showPopover, setShowPopover] = useState(false);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    setShowPopover(true);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setShowPopover(false);
  };

  const openPopover = Boolean(anchorEl);

  return (
    <>
      <Tooltip title={bodyInfo} placement="right">
        <IconButton
          aria-describedby={openPopover ? "popover" : undefined}
          onClick={handleClick}
        >
          <InfoSharpIcon />
        </IconButton>
      </Tooltip>

      <Popover
        id="popover"
        open={openPopover}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        PaperProps={{
          sx: {
            width: "40%",
            padding: "16px",
            height: "70%",
            boxShadow: 20,
            mt: 2, // Add margin-top to push the popover down
          },
        }}
      >
        <Typography>{completeInfo}</Typography>
        <Button onClick={handleClose}>Close</Button>
      </Popover>
    </>
  );
}
