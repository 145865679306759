import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import * as React from "react";
import { Controller, useFormContext } from "react-hook-form";
import { FormDataSchema } from "../types/formtypes";

export default function RoleAutocomplete(props) {
  const { roles } = props;
  const { control } = useFormContext<FormDataSchema>();
  return (
    <Controller
      name="role"
      control={control}
      render={({
        field: { onChange, value, ref, onBlur },
        fieldState: { error },
      }) => (
        <Autocomplete
          value={value}
          onChange={(_, newValue) => onChange(newValue)}
          id="controllable-states-demo"
          options={roles}
          sx={{ width: "33%" }}
          renderInput={(params) => (
            <TextField
              inputRef={ref}
              onBlur={onBlur}
              {...params}
              label="Enter desired role"
              error={!!error}
              helperText={error ? error.message : ""}
            />
          )}
        />
      )}
    />
  );
}
