import React from "react";
import "react-notifications/lib/notifications.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import CVForm from "./CVForm";

// Component
const App: React.FC = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<CVForm />} />
      </Routes>
    </BrowserRouter>
  );
};

export default App;
