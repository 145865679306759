import React from "react";
import { Stack, Typography } from "@mui/material";
import AddSubSectionButton from "../UI/AddSubSectionButton";
import ListProjects from "./ListProjects";
import ListPublications from "./ListPublications";
import { useFieldArray, useFormContext } from "react-hook-form";
import {
  PERSONAL_PROJECTS_DEFAULT_OBJECT,
  PUBLICATIONS_DEFAULT_OBJECT,
} from "../../utils/Constants";

export default function Personal(): JSX.Element {
  const methods = useFormContext();
  const {
    fields: projects,
    append: appendProject,
    remove: removeProject,
  } = useFieldArray({
    control: methods.control,
    name: "personal_projects",
  });

  const {
    fields: publications,
    append: appendPublication,
    remove: removePublication,
  } = useFieldArray({
    control: methods.control,
    name: "publications",
  });

  const addPublication = () => {
    appendPublication(PUBLICATIONS_DEFAULT_OBJECT);
  };
  const addPersonalProject = () => {
    appendProject(PERSONAL_PROJECTS_DEFAULT_OBJECT);
  };

  return (
    <Stack>
      <Stack direction="row" gap={1} pl={2}>
        <Typography
          id="projects_publications_id"
          variant="h5"
          style={{ marginTop: "3px" }}
        >
          Personal Projects And Publications
        </Typography>
      </Stack>
      <Stack direction="column" spacing={2} pt={1}>
        <ListProjects projects={projects} removeProject={removeProject} />
        <AddSubSectionButton onClick={addPersonalProject} name="Add Project" />
        <ListPublications
          publications={publications}
          removePublication={removePublication}
        />
        <AddSubSectionButton onClick={addPublication} name="Add Publication" />
      </Stack>
    </Stack>
  );
}
