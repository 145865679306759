import React from "react";
import { Stack, FormHelperText } from "@mui/material";
import { THESIS_PROJECT } from "../../utils/Constants";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { Controller, useFormContext } from "react-hook-form";
import DeleteSubSectionButton from "../UI/DeleteSubSection";
import FormTextField from "../UI/FormTextField";

export default function ItemThesisProject(props) {
  const { remove } = props;

  const methods = useFormContext();
  const {
    control,
    formState: { errors },
  } = methods;

  const removeCurrentThesisProject = () => {
    remove(props.index);
  };
  return (
    <Stack spacing={2} width={"100%"} p={1}>
      <Stack direction="row" alignItems="center" spacing={2} width={"100%"}>
        <FormControl fullWidth>
          <InputLabel>Type</InputLabel>
          <Controller
            name={`education.${props.educationIndex}.education_projects.${props.index}.project_type`}
            control={control}
            render={({ field, fieldState: { error } }) => (
              <Select
                inputRef={field?.ref}
                value={field?.value ?? ""}
                onBlur={field?.onBlur}
                onChange={field?.onChange}
                error={!!error}
              >
                {THESIS_PROJECT.map((type, index) => (
                  <MenuItem key={index} value={type}>
                    {type}
                  </MenuItem>
                ))}
              </Select>
            )}
          />

          <FormHelperText error={true}>
            {!!errors.education?.[props.educationIndex]?.education_projects?.[
              props.index
            ]?.project_type
              ? errors.education?.[props.educationIndex]?.education_projects?.[
                  props.index
                ]?.project_type?.message
              : ""}
          </FormHelperText>
        </FormControl>
        <Controller
          name={`education.${props.educationIndex}.education_projects.${props.index}.project_title`}
          control={control}
          render={({ field, fieldState: { error } }) => (
            <FormTextField
              field={field}
              error={error}
              fullWidth
              required
              label="Title"
            />
          )}
        />
        <DeleteSubSectionButton onClick={removeCurrentThesisProject} />
      </Stack>
      <Controller
        name={`education.${props.educationIndex}.education_projects.${props.index}.project_description`}
        control={control}
        render={({ field, fieldState: { error } }) => (
          <FormTextField
            field={field}
            error={error}
            label="Description"
            multiline
            rows={3}
            fullWidth
          />
        )}
      />
    </Stack>
  );
}
