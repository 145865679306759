import React from "react";
import { Fab } from "@mui/material";
import SaveIcon from "@mui/icons-material/Save";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import {
  PUT_SAVE_DOC,
  SERVER,
  SERVER_API_KEY,
  SERVER_API_SECRET,
} from "../../api/server";
import {
  useModalContextOpen,
  useModalContextUpload,
} from "../../features/ModalContext";
import { useFormContext } from "react-hook-form";
import { convertFormDataToServer } from "../../utils/utils";
//Component
export default function SaveBtn(props) {
  // const isFirstRender = useRef(true);
  const { show } = useModalContextOpen();
  const { showUpload } = useModalContextUpload();

  const { getValues } = useFormContext();

  // Send query to Save endpoint in the API
  function handleSave(e) {
    e.preventDefault();
    const allInfo = convertFormDataToServer(getValues());
    if (allInfo?.name === "" || allInfo?.role === "" || allInfo?.email === "") {
      NotificationManager.warning(
        "Please fill at least name, role and email",
        "Error",
        2000,
      );
    } else {
      // Send it to the API
      fetch(
        SERVER +
          PUT_SAVE_DOC +
          "?resume_key=" +
          allInfo.name +
          "-" +
          allInfo.email,
        {
          method: "PUT",
          headers: {
            accept: "application/json",
            "Content-Type": "application/json",
            "x-api-key": SERVER_API_KEY,
            "x-api-key-secret": SERVER_API_SECRET,
          },
          body: JSON.stringify(allInfo),
        },
      )
        .then((response) => response.json())
        .then((d) => {
          NotificationManager.success("Form Saved", "Save status", 2000);
        })
        .catch((err) => {
          console.error(err);
        });
    }
  }

  return (
    <>
      <Fab
        variant="extended"
        aria-label="Save Button"
        sx={{
          bgcolor: "#1B418C",
          color: "white",
          "&:hover": {
            backgroundColor: "#0071B2",
          },
        }}
        id="save-btn"
        data-testid="test-save-btn"
        className="btnApp"
        type="save"
        onClick={handleSave}
        disabled={show || showUpload}
      >
        <SaveIcon />
        Save Form
      </Fab>
      <NotificationContainer />
    </>
  );
}
