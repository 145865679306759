import React from "react";
import {
  PUBLICATION_COMPLETE_INFO,
  PUBLICATION_LIGHT_INFO,
} from "../../utils/Constants";
import InfoBtn from "../UI/InfoBtn";
import { Stack, Typography } from "@mui/material";
import ItemPublication from "./ItemPublication";

//Component
export default function ListPublications({ removePublication, publications }) {
  return (
    <Stack direction={"row"} pl={2} gap={4} alignItems={"flex-start"}>
      <Typography variant="h6">Publications</Typography>
      <InfoBtn
        titleInfo="Publications"
        bodyInfo={
          <div
            dangerouslySetInnerHTML={{
              __html: PUBLICATION_LIGHT_INFO,
            }}
          />
        }
        completeInfo={
          <div
            dangerouslySetInnerHTML={{
              __html: PUBLICATION_COMPLETE_INFO,
            }}
          />
        }
      />
      <Stack direction={"column"} gap={1} width={"100%"}>
        {publications
          ? publications.map((publication, index) => (
              <ItemPublication
                key={publication.id}
                index={index}
                removePublication={removePublication}
              />
            ))
          : null}
      </Stack>
    </Stack>
  );
}
