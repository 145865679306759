import React from "react";
import { Button } from "@mui/material";
//Component
export default function DeleteSubSectionButton({ onClick }) {
  return (
    <Button
      variant="contained"
      onClick={onClick}
      disableElevation={true}
      sx={{
        color: "red",
        backgroundColor: "#F2F2F2",
        borderColor: "red",
        "&:hover": {
          backgroundColor: "lightgray",
        },
      }}
    >
      x
    </Button>
  );
}
