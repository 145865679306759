import React from "react";
import LinearProgress, {
  LinearProgressProps,
} from "@mui/material/LinearProgress";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

export default function LinearProgressWithLabel(
  props: LinearProgressProps & { value: number },
): JSX.Element {
  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <Box sx={{ width: "100%", mr: 1 }}>
        <Typography variant="h6" color={"white"}>
          Factored Resume Progress Bar
        </Typography>
        <LinearProgress
          variant="determinate"
          {...props}
          sx={{
            height: 20, // Adjust thickness here
            borderRadius: 5, // Optional: rounded corners
            "& .MuiLinearProgress-bar": {
              backgroundColor: "#4caf50", // Bar color
            },
            border: "2px solid black", // Black border
          }}
        />
      </Box>
      <Box sx={{ minWidth: 35 }}>
        <Typography variant="body2" color="white">
          {`${Math.round(props.value)}%`}
        </Typography>
      </Box>
    </Box>
  );
}
