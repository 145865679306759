import React from "react";
import { Button, Stack } from "@mui/material";
import { Controller, useFormContext } from "react-hook-form";
import FormTextField from "../UI/FormTextField";

//Component
export default function ItemProject(props) {
  const { removeProject } = props;

  const { control } = useFormContext();
  return (
    <Stack direction={"row"} id={`personal_projects_${props.index}_id`}>
      <Controller
        name={`personal_projects.[${props.index}].text`}
        control={control}
        render={({ field, fieldState: { error } }) => (
          <FormTextField
            field={field}
            error={error}
            placeholder="Description"
            multiline
            rows={3}
            sx={{ width: "95%" }}
          />
        )}
      />
      <Button
        variant="contained"
        onClick={() => removeProject(props.index)}
        disableElevation={true}
        sx={{
          color: "red",
          backgroundColor: "#F2F2F2",
          "&:hover": {
            backgroundColor: "lightgray",
          },
        }}
      >
        x
      </Button>
    </Stack>
  );
}
