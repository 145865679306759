import React, { useState } from "react";
import { Button, Dropdown, DropdownButton, Modal } from "react-bootstrap";
import { Fab } from "@mui/material";
import FileOpenIcon from "@mui/icons-material/FileOpen";
import {
  useModalContextOpen,
  useModalContextUpload,
} from "../../features/ModalContext";
import { useFormContext } from "react-hook-form";
import { convertServerDataToForm } from "../../utils/utils";
import { getFormNames, getRecentForm } from "../../api/OpenRecentApi";

export default function Btn(props) {
  const [dataList, setDataList] = useState([]);
  const [selected, setSelected] = useState("Name");
  const { show, setShow } = useModalContextOpen();
  const { showUpload } = useModalContextUpload();

  const { reset } = useFormContext();
  // It handles the click in close button of the pop over
  const handleClose = () => setShow(false);

  // It handles what happens when shows the pop over
  const handleShow = async () => {
    setShow(true);
    const resume_keys = await getFormNames();
    setDataList(resume_keys);
  };

  // It handles the item that was clicked in the dropdown list
  const handleClick = (e) => {
    setSelected(e.target.innerHTML);
  };

  // It handles the click in the Open button
  const handleSubmit = async () => {
    handleClose();
    // Get the resume from the server with the selected name
    try {
      const resume_data = await getRecentForm(selected);
      const formData = convertServerDataToForm(resume_data);
      reset(formData);
    } catch (e) {
      console.error(e);
    }
  };

  /** Render */
  return (
    <>
      <Fab
        variant="extended"
        aria-label="Open Button"
        sx={{
          bgcolor: "#1B418C",
          color: "white",
          "&:hover": {
            backgroundColor: "#0071B2",
          },
        }}
        className="btnApp"
        type="submit"
        onClick={handleShow}
        disabled={show || showUpload}
      >
        <FileOpenIcon />
        Open Recent
      </Fab>
      <Modal size="lg" show={show} onHide={handleClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>Open recent forms</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <DropdownButton
            variant="outline-secondary"
            title={selected}
            id="input-group-dropdown-1"
          >
            {dataList
              ? dataList.map((e, i) => (
                  <Dropdown.Item key={i} href="#" onClick={handleClick}>
                    {e}
                  </Dropdown.Item>
                ))
              : null}
          </DropdownButton>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          {/* TODO Make Open functional */}
          <Button variant="primary" onClick={handleSubmit}>
            Open
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
