import type {
  SectionType,
  ServerExperienceSchema,
  ServerFormDataSchema,
  TableOfContentsType,
  TableType,
  FormDataSchema,
  ExperienceSchema,
} from "../types/formtypes";

import {
  DEGREE_TYPES,
  MAX_LETTERS_TABLE_OF_CONTENTS,
  MONTHS_TO_NUMS,
  NUMS_MONTHS,
} from "./Constants";

const formatDate = (dateStr: string) => {
  if (dateStr == null) return dateStr;
  const [year, month] = dateStr?.split("-");
  return {
    year: parseInt(year, 10),
    month: NUMS_MONTHS[month],
  };
};

//personal_projects + publications + experience/bullets

export const convertFormDataToServer = (
  data: FormDataSchema,
): ServerFormDataSchema => {
  const experience: ServerExperienceSchema[] = data["experience"]?.map(
    (exp) => {
      const { year: start_year, month: start_month } = formatDate(
        exp.start_date,
      );
      const end = formatDate(exp.end_date);
      return {
        company: exp.company,
        title: exp.title,
        start_year,
        start_month,
        end_year: end.year,
        end_month: end.month,
        bullets: exp.bullets.map((bullet) => bullet.text),
      };
    },
  );
  const personal_projects: string[] = data.personal_projects?.map(
    (project) => project.text,
  );

  const publications: string[] = data.publications?.map(
    (publication) => publication.text,
  );
  return {
    ...data,
    experience,
    personal_projects,
    publications,
  };
};

const formatDateString = (year: number, month: string) => {
  const formattedMonth = MONTHS_TO_NUMS[month];
  if (formattedMonth) return `${year}-${formattedMonth.padStart(2, "0")}-01`;
  return "";
};

export const convertServerDataToForm = (
  data: ServerFormDataSchema,
): FormDataSchema => {
  const experience = data.experience?.map((exp) => {
    const start_date = formatDateString(exp.start_year, exp.start_month);
    const end_date = formatDateString(exp.end_year, exp.end_month);
    return {
      company: exp?.company,
      title: exp?.title,
      start_date,
      end_date,
      bullets: exp.bullets?.map((bullet) => ({
        text: bullet,
      })),
    };
  });

  const education = data.education?.map((cur_education) => {
    if (
      cur_education.degree_type != null &&
      !DEGREE_TYPES.includes(cur_education.degree_type)
    )
      cur_education.degree_type = "";
    return cur_education;
  });

  const personal_projects = data?.personal_projects?.map((project) => ({
    text: project,
  }));

  const publications = data?.publications?.map((publication) => ({
    text: publication,
  }));

  return {
    ...data,
    education,
    experience,
    personal_projects,
    publications,
  };
};

export const ensureError = (value: unknown): Error => {
  if (value instanceof Error) return value;

  let stringified = "[Unable to stringify the thrown value]";
  try {
    stringified = JSON.stringify(value);
  } catch {}

  const error = new Error(
    `This value was thrown as is, not through an Error: ${stringified}`,
  );
  return error;
};
const getSubSectionObj = (
  section: string,
  field: string,
  name: string,
  index: number,
): SectionType => {
  if (name?.length > MAX_LETTERS_TABLE_OF_CONTENTS) {
    name = name.slice(0, MAX_LETTERS_TABLE_OF_CONTENTS) + "...";
  }
  return {
    text: `${index + 1}. ${field.replace("_", " ")}: ${name}`,
    id: `${section}_${index}_id`,
  };
};
export function convertFormDataToTableOfContents(
  formData: FormDataSchema,
): TableOfContentsType {
  const sections: TableType = {};

  // Iterate over the keys of the FormDataSchema
  for (const key of Object.keys(formData)) {
    const value = formData[key as keyof FormDataSchema];

    // Check if the value is an array
    if (Array.isArray(value) && value.length > 0) {
      sections[key] = {
        id: key + "_id",
        text: (key.charAt(0).toUpperCase() + key.slice(1)).replace("_", " "), // Capitalize the section name
        sub_sections: value.map((item, index) =>
          getSubSectionObj(
            key,
            Object.keys(item)[0],
            item[Object.keys(item)[0]],
            index,
          ),
        ),
      };
    }
  }

  return { sections };
}
