import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogActions,
  Button,
  DialogContent,
  DialogContentText,
} from "@mui/material";

export const SuccessDialog = ({
  handleCloseSuccessDialog,
  linkFormSubmitted,
  open,
}): JSX.Element => {
  return (
    <Dialog
      open={open}
      onClose={handleCloseSuccessDialog}
      aria-labelledby="responsive-dialog-title"
    >
      <DialogTitle id="responsive-dialog-title">
        {"Submitted successfully!"}
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          You can access the document clicking{" "}
          <a href={linkFormSubmitted} target="_blank">
            here.
          </a>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          variant="extended"
          aria-label="Submit Button"
          sx={{
            bgcolor: "#1B418C",
            color: "white",
            "&:hover": {
              backgroundColor: "#0071B2",
            },
          }}
          autoFocus
          onClick={handleCloseSuccessDialog}
        >
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};
