import React from "react";
import { Button } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
//Component
export default function DeleteSectionButton({ handleShow, deleteName }) {
  return (
    <>
      <Button
        variant="contained"
        color="error"
        startIcon={<DeleteIcon />}
        sx={{
          color: "white",
          borderRadius: "16px",
          width: "20%",
          alignSelf: "center",
        }}
        onClick={handleShow}
      >
        {deleteName}
      </Button>
    </>
  );
}
