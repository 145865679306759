import React, { useState, useEffect, useRef, RefObject } from "react";
import MyForm from "./MyForm";
import Cookies from "universal-cookie";

import { isValidationError } from "./types/formtypes";

import Auth from "./components/Auth";
import { Stack } from "@mui/material";
import { convertFormDataToServer, ensureError } from "./utils/utils";
import { FormDataSchema, MyFormHandle } from "./types/formtypes";
import { submitFormRequest } from "./api/FormApi";
import { useMutation } from "@tanstack/react-query";

export default function CVForm(): JSX.Element {
  const [logStatus, setLogStatus] = useState(false);

  const formRef: RefObject<MyFormHandle> = useRef<MyFormHandle>(null);

  const { REACT_APP_FACTORED_AUTH_USERNAME, REACT_APP_FACTORED_AUTH_PASSWORD } =
    process.env;

  const handleSubmitTrigger = () => {
    if (formRef.current) {
      formRef.current.submitForm();
    }
  };

  const cookies = new Cookies();

  useEffect(() => {
    if (
      cookies.get("email") === REACT_APP_FACTORED_AUTH_USERNAME &&
      cookies.get("password") === REACT_APP_FACTORED_AUTH_PASSWORD
    ) {
      setLogStatus(true);
    }
  }, [logStatus]);

  const handleLog = () => {
    setLogStatus(true);
  };

  const handleFormSubmit = async (data: FormDataSchema) => {
    formRef.current?.setLoadingSubmitButton(true);
    const body = convertFormDataToServer(data);
    try {
      const response = await submitFormRequest(body);
      if (isValidationError(response)) {
        console.error("SERVER VALIDATION ERROR | CVForm");
        const name = response.detail[0].loc
          .map((element) => `[${element}]`)
          .join("");
        if (formRef.current) {
          //TODO: Error del back puede no funcionar con los fields que son diferentes con el FASTAPI SCHEMA
          formRef.current.setErrors(name, response.detail[0].msg);
        }
      } else {
        if (formRef.current && response.document_url) {
          formRef.current.openSuccessDialog(response.document_url);
        }
      }
      formRef.current?.setLoadingSubmitButton(false);
    } catch (err) {
      const error = ensureError(err);
      console.error(error);
      formRef.current?.setLoadingSubmitButton(false);
      formRef.current?.openErrorDialog(error);

      throw error;
    }
  };

  return (
    <>
      {logStatus ? (
        <Stack
          direction={"row"}
          justifyContent={"center"}
          gap={10}
          bgcolor={"#F2F2F2"}
        >
          <MyForm
            ref={formRef}
            onSubmit={handleFormSubmit}
            handleSubmitTrigger={handleSubmitTrigger}
          />
        </Stack>
      ) : (
        <Auth handleLog={handleLog} />
      )}
    </>
  );
}
