import { z } from "zod";

import { educationSchema } from "./educationSchema";
import { experienceSchema } from "./experienceSchema";
import { courseSchema } from "./courseSchema";
import { certificationSchema } from "./certificationSchema";
import { textSchema } from "./textSchema";

export const formSchema = z.object({
  name: z.string().min(1, "Name is required"),
  role: z.string().min(1, "Role is required"),
  email: z.string().email("Invalid email address"),
  summary: z.string().min(1, "Summary is required"),
  skills: z.string().min(1, "atleast one skill is required"),

  education: z.array(educationSchema),
  experience: z.array(experienceSchema),
  certifications: z.array(certificationSchema),
  courses: z.array(courseSchema),
  personal_projects: z.array(textSchema),
  publications: z.array(textSchema),
});
