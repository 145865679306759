import React from "react";
import {
  Stack,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
  TextField,
} from "@mui/material";
import { Controller, useFormContext } from "react-hook-form";

import { DEGREE_TYPES, DEGREE_STATUS } from "../../utils/Constants";
import ListThesisProjects from "./ListThesisProjects";
import { useState } from "react";

import DeleteSectionButton from "../UI/DeleteSectionButton";
import ConfirmationModal from "../ConfirmationModal";
import FormTextField from "../UI/FormTextField";
import { Unstable_NumberInput as NumberInput } from "@mui/base/Unstable_NumberInput";

export default function ItemEducation(props) {
  const { removeEducation } = props;

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const methods = useFormContext();

  const {
    control,
    formState: { errors },
  } = methods;

  const deleteEducation = () => {
    handleClose();
    removeEducation(props.index);
  };
  return (
    <div>
      <Stack
        id={`education_${props.index}_id`}
        spacing={1}
        direction="row"
        p={1}
      >
        <Controller
          name={`education.[${props.index}].institution`}
          control={control}
          render={({ field, fieldState: { error } }) => (
            <FormTextField
              field={field}
              error={error}
              label="Institution"
              placeholder="Institution"
            />
          )}
        />
        <Stack
          direction="row"
          spacing={0}
          justifyContent={"center"}
          width={"50%"}
        >
          <FormControl fullWidth>
            <InputLabel>Degree</InputLabel>
            <Controller
              name={`education.[${props.index}].degree_type`}
              control={control}
              render={({ field, fieldState: { error } }) => (
                <Select
                  inputRef={field?.ref}
                  value={field?.value ?? ""}
                  onBlur={field?.onBlur}
                  onChange={field?.onChange}
                  error={!!error}
                >
                  {DEGREE_TYPES.map((type, index) => (
                    <MenuItem key={index} value={type}>
                      {type}
                    </MenuItem>
                  ))}
                </Select>
              )}
            />
            <FormHelperText error={true}>
              {!!errors.education?.[props.index]?.degree_type
                ? errors.education?.[props.index]?.degree_type?.message
                : ""}
            </FormHelperText>
          </FormControl>
          <Controller
            name={`education.[${props.index}].degree_name`}
            control={control}
            render={({ field, fieldState: { error } }) => (
              <FormTextField
                field={field}
                error={error}
                label="Name"
                placeholder="Name"
              />
            )}
          />
        </Stack>
        <Stack direction="row" spacing={1}>
          <FormControl fullWidth>
            <InputLabel>Status</InputLabel>
            <Controller
              name={`education.[${props.index}].education_status`}
              control={control}
              render={({ field, fieldState: { error } }) => (
                <Select
                  inputRef={field?.ref}
                  value={field?.value ?? ""}
                  onBlur={field?.onBlur}
                  onChange={field?.onChange}
                  error={!!error}
                >
                  {DEGREE_STATUS.map((status, index) => (
                    <MenuItem key={index} value={status}>
                      {status}
                    </MenuItem>
                  ))}
                </Select>
              )}
            />
            <FormHelperText error={true}>
              {!!errors.education?.[props.index]?.education_status
                ? errors.education?.[props.index]?.education_status?.message
                : ""}
            </FormHelperText>
          </FormControl>
          <Controller
            name={`education.[${props.index}].degree_year`}
            control={control}
            render={({ field, fieldState: { error } }) => (
              <TextField
                inputRef={field?.ref}
                value={field?.value ?? ""}
                onBlur={field?.onBlur}
                onChange={(e) => {
                  field.onChange(e.target.valueAsNumber);
                }}
                error={!!error}
                helperText={error?.message}
                label="Year"
                type="Number"
                placeholder="Year"
              />
            )}
          />
        </Stack>
      </Stack>
      <ListThesisProjects educationIndex={props.index} />
      <Stack direction="row" spacing={1} p={1} justifyContent={"center"}>
        <DeleteSectionButton
          deleteName={"DELETE EDUCATION"}
          handleShow={handleShow}
        />
        <ConfirmationModal
          show={show}
          handleClose={handleClose}
          handleConfirmation={deleteEducation}
          actionName={"Delete Education?"}
        />
      </Stack>
    </div>
  );
}
