import React from "react";
import { Stack } from "@mui/material";
import { Controller, useFormContext } from "react-hook-form";
import DeleteSubSectionButton from "../UI/DeleteSubSection";
import FormTextField from "../UI/FormTextField";

export default function ItemBullet(props) {
  const { experienceIndex, index, remove } = props;
  const { control } = useFormContext();

  const removeCurrentBullet = () => {
    remove(index);
  };
  return (
    <Stack direction="row" alignItems="center" spacing={1}>
      <Controller
        name={`experience.[${experienceIndex}].bullets.[${index}].text`}
        control={control}
        render={({ field, fieldState: { error } }) => (
          <FormTextField
            field={field}
            error={error}
            placeholder="Enter something relevant that you worked on during this experience"
            multiline
            rows={3}
            sx={{ width: "95%" }}
            required
          />
        )}
      />

      <DeleteSubSectionButton onClick={removeCurrentBullet} />
    </Stack>
  );
}
