import React from "react";
import ItemCertification from "./ItemCertification";
//Component
export default function ListCertifications({ removeCertification, fields }) {
  return (
    <>
      {fields
        ? fields.map((cert, index) => (
            <ItemCertification
              key={cert.id}
              index={index}
              removeCertification={removeCertification}
            />
          ))
        : null}
    </>
  );
}
